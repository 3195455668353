import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { defaultLocale } from '../config/i18n.config';
import { LocaleProvider } from '../context/localeContext';
import BlogRollPaginated from '../components/BlogRollPaginated';
import Layout from '../components/Layout';
import Section from '../components/Section';
import ReaserchPage from './ResearchPage';
import NewsletterSignupForm from '../components/NewsletterSignupForm';

const PressPageTemplate = ({ data, pageContext }) => {
  const title =
    data.pressPage.frontmatter[pageContext.locale].title ||
    data.pressPage.frontmatter[defaultLocale].title;
  const heroImage = data.pressPage.frontmatter[defaultLocale].image;
  const heroContent =
    data.pressPage.frontmatter[pageContext.locale].hero ||
    data.pressPage.frontmatter[defaultLocale].hero;
  const pressReleases = data.pressReleases.edges;

  return (
    <LocaleProvider locale={pageContext.locale}>
      <Layout>
        <ReaserchPage
          title={title}
          heroImage={heroImage}
          heroContent={heroContent}
        />
        <BlogRollPaginated
          items={pressReleases}
          templateFilters={[
            {
              value: 'PressReleaseTemplate',
              text: 'Press Release',
              color: 'yellow',
            },
            {
              value: 'InTheNewsTemplate',
              text: 'In The News',
              color: 'green',
            },
          ]}
        />
        <Section>
          <NewsletterSignupForm />
        </Section>
      </Layout>
    </LocaleProvider>
  );
};

PressPageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PressPageTemplate;

export const pressPageQuery = graphql`
  query PressPage($id: String!, $locale: String!) {
    pressPage: markdownRemark(id: { eq: $id }) {
      frontmatter {
        en {
          title
          hero
          image {
            childImageSharp {
              gatsbyImageData(quality: 90, layout: FULL_WIDTH)
            }
          }
        }
        de {
          title
          hero
        }
      }
    }
    pressReleases: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        fields: {
          collection: { in: ["press-releases", "in-the-news"] }
          locale: { eq: $locale }
        }
        frontmatter: { title: { ne: "" } }
        rawMarkdownBody: { ne: "" }
      }
    ) {
      edges {
        node {
          ...BlogRollItem
        }
      }
    }
  }
`;
